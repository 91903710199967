<template>
  <div class="home">
    <div id="homeContent" class="content">
      <div id="bigTitle">
        {{ $t("bigTitle") }}
      </div>
      <div class="respect-linebreaks home-text">
        {{ $t("homeLongText") }}
      </div>
      <div id="smallTitle" class="kbc-normal-light home-text">
        {{ $t("smallTitle") }}
      </div>
      <div class="clearfix"></div>
      <div id="poweredby">
        <img alt="KBC logo" src="../assets/kbc.svg" />
        <img alt="Soluzio logo" src="../assets/soluzio.svg" />
        <img alt="Orange logo" src="../assets/orange.png" />
      </div>
      <div id="btnContainer">
        <div id="btnBackgroundContainer">
          <button id="startTestBtn" @click="gonext">
            {{ $t("homeButton") }}
          </button>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../router";

export default {
  name: "Home",
  mounted() {
    //this.$store.commit("SET_HISTORYLENGTH", window.history.length);
    //this.$store.commit("SET_DOCUMENTREFERRER", document.referrer);
    this.$store.commit("SET_HISTORYLENGTH", 0);
    this.$store.commit("SET_DOCUMENTREFERRER", "");
  },
  methods: {
    gonext() {
      router.push("Index");
    },
  },
};
</script>

<style scoped>
#bigTitle {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 24px;
  white-space: pre-line;
}
#smallTitle {
  margin-top: 15px;
  margin-bottom: 16px;
}
#btnBackgroundContainer {
  width: 426.6px;
  margin: 0 auto;
  background: url("../assets/arrows.png") no-repeat;
  height: 324px;
  background-size: 426.6px 324px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#poweredby {
  display: flex;
}
#poweredby img {
  object-fit: contain;
  margin-left: 8px;
  width: 48px;
  height: 48px;
}
#poweredby img:first-child {
  margin-left: unset;
}
#btnContainer,
title {
  width: 100%;
}
.title {
  float: left;
}
.productName {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

@media only screen and (max-width: 768px) {
  #bigTitle {
    font-size: 30px;
  }
  #btnBackgroundContainer {
    width: 319.95px;
    height: 243px;
    background-size: 319.95px 243px;
  }
  #poweredby {
    margin-bottom: 45px;
  }
  .home-text {
    font-size: 14px;
  }
}
@media only screen and (max-width: 374px) {
  button {
    padding: 7px 15px 7px 15px;
  }
  #btnBackgroundContainer {
    width: 239.9625px;
    height: 182.25px;
    background-size: 239.9625px 182.25px;
  }
}
@media (max-width: 320px) {
  #bigTitle {
    font-size: 28px;
  }
}
</style>
