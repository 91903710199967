<template>
  <div class="languageBar">
    <div id="customSelect" class="languageSelectWrapper">
      <select
        name="langSelect"
        id="languageSelect"
        @change="onSelectChange($event)"
      >
        <option value="nl" v-if="!isMobile()">Nederlands</option>
        <!--<option value="fr" v-if="!isMobile()">Francais</option>-->
        <option value="de" v-if="!isMobile()">Deutsch</option>
        <!--<option value="en" v-if="!isMobile()">English</option>-->
        <option value="nl" v-if="isMobile()">NL</option>
        <!--<option value="fr" v-if="isMobile()">FR</option>-->
        <option value="de" v-if="isMobile()">DE</option>
        <!--<option value="en" v-if="isMobile()">EN</option>-->
      </select>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import i18n from "../i18n";

export default {
  name: "LanguageBar",
  methods: {
    onSelectChange(event) {
      var lang = event.target.value;
      i18n.locale = lang;
    },
    isMobile: function () {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.languageSelectWrapper select:focus {
  outline: none;
}

.languageSelectWrapper select {
  cursor: pointer;
  color: rgb(0, 174, 239);
  font-weight: bold;
  margin-right: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border: 0px none;
  box-shadow: none !important;
}

@media only screen and (max-width: 768px) {
  .languageSelectWrapper select {
    margin-right: unset;
  }
}
@media print {
  .languageBar {
    display: none;
  }
}
</style>
