import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
//\u000a for line break

const messages = {
  en: {
    next: "Next",
    yes: "Yes",
    no: "No",
    unsure: "Unsure",
    calculateScore: "Calculate score",
    step: "Step {no} of 3",
    bigTitle: "How secure is your company?",
    smallTitle:
      "Take this Security Assesment test and we'll provide you tips on how to improve your cyber security and awareness. This test is a collaboration between KBC, Soluzio and Orange Cyberdefence.",
    questionTitle:
      "Please answer below questions to complete the {name} assessment",
    moreInfo:
      "For a guide or more information on this assessment, check out the ",
    documentationHrefTitle: "documentation",
    homeButton: "Take the test",
    Index: {
      title:
        "It takes 5 minutes to run through a few key questions within the following operational risk families",
      people: "People",
      process: "Process",
      technology: "Technology",
      score:
        "Upon completion, you will get a score and an insight in valuable recommendations.",
    },
    People: {
      question1:
        "Does your IT team has a cybersecurity specialist (or is this outsourced to a partner)?",
      question2:
        "Does your company have a cybersecurity employee awareness program in place?",
      question3:
        "Does your company have a DPO (data protection officer), internal or external?",
      question4:
        "Is it difficult to recruit, retain IT / cybersecurity employees?",
      question5:
        "Does your IT staff rehearses disaster recovery scenario's at least once a year?",
    },
    Process: {
      question1:
        "Has your company digitized its business processes (e-invoicing, …)",
      question2: "As a CEO, do you know your cybersecurity risks?",
      question3:
        "Are you - as a CEO - comfortable how cyber incidents are handled in your company?",
      question4: "Does your company have a cybersecurity insurance?",
      question5:
        "Does your company have a CSIRT (cybersecurity incident response team) contract in place?",
    },
    Technology: {
      question1:
        "Does your company have visibility on what's happening in your network and cloud?",
      question2:
        "Is your company capable of proactive montoring and detection of cybersecurity incidents?",
      question3:
        "Does your company manage 3rd party access to its data and systems?",
      question4:
        "Does your current network and systems setup adheres to the Zero-trust philosophy?",
      question5: "Does your company use data loss prevention tools?",
    },
    PeopleScore: {
      score1:
        "In level 1 organizations IT is in charge for everything IT related, with little or no budget for security controls. The employees of the organization have no awareness on security as well: shadow IT, easy guessable passwords, unlocked computer systems, confidential information on desks, etc.",
      score2:
        "In most level 2 organizations the person responsible for IT Management gets Security as a secondary responsibility. Often the IT manager relies on another very technical decision maker within the organization to manage the security aspects of the organization. It is possible there is a lack of leadership in the management layer of the organization when it comes to security.",
      score3:
        "Most level 3 organizations have a dedicated IT security team with well-defined responsibilities for each member. Security awareness among users is well established and continuously improving. If the organization is doing in-house development, their developers start to organize themselves for a Secure Software Development Life Cycle.",
      score4:
        "In a level 4 organization all users are aware of the importance of security while the security team is organized to take the appropriate actions on any computer and security incident while regular tasks. However, the security aware users are the next liability for organizations that reached the fourth level.",
      score5: "Wow what a great score!",
      recommendation:
        "To go up a level we would strongly recommend looking into a partnership with Soluz.io",
    },
    ProcessScore: {
      score1:
        "The level 1 organization has no security policy or program, the incidents that happen usually impact the business activities. The IT-department has to fix them when they occur. ",
      score2:
        "Level 2 organizations have the basic security processes (partly) covered (asset inventories, patch management, disaster recovery, business continuity, …) and are aware on what is happening on the network and within their IT infrastructure. However, when incidents happen, knowing the cause is the result of a time-consuming root-cause analysis and they cannot solve it right away.",
      score3:
        "A level 3 organization has the basic security processes well established. The additional processes center around improving detection capabilities. The detection of vulnerabilities, security information and event logging while gaining control over internal threats as well. A periodic penetration test is conducted as a detection process. The results of the detection processes start to evolve into a periodic incident response process.",
      score4:
        "The security improving processes are well established and a continuous process for the security team. Security risk are assessed as an ongoing process and the detection capabilities grow into the user and entity behavior space. The user finds an extension into privileged account management and threats are shared with peers as a process. Organizations start with red (hackers) and blue (defenders) team exercises to simulate real-life Cyber Security scenario’s to be prepared.",
      score5: "Perfect score!",
      recommendation:
        "A partnership with KBC and Soluz.io would greatly increase your odds of gaining a level.",
    },
    TechnologyScore: {
      score1:
        "Typical security technologies you find in the IT environment of level 1 organizations are a firewall, a signature based anti-malware solution, an anti-spam solution in the cloud and probably a back-up solution that isn’t used on a regular basis. Remote access to the organization is typically done via Remote Desktop Services directly connected to the Internet or a simple firewall build-in SSL VPN.",
      score2:
        "Typical security technologies you find in the IT environment of level 2 organizations are a next-generation firewall, an anti-malware solution, an e-mail security solution, a back-up solution and possibly one or more of the following: a SSL VPN, a proxy, a reverse proxy, a separate WiFi-network for guests, segmentation on the network, (two-factor authentication), a high availability configuration over two data centers or maybe even a sandbox to detect 0-day threats. Most of the technologies are used to protect the perimeter and the endpoints, there is a lack in focus on the internal network, their systems and their data.",
      score3:
        "Typical security technologies in a level 3 organization focus on the internal network, the systems and the data. The security team is architecting and implementing the security controls while they are maintained by the IT department and monitored by the security team. The security technologies that are added in level 3 are SIEM, Segmentation Firewalls, Network Access Control, Identity and Access Management as well as the first steps to data discovery and classification technologies.",
      score4:
        "The technology focus for a level 4 organization is centered around (un-)intentional actions of users that compromise security or privacy. Technologies that monitor security configuration, Data Loss and Exfiltration, Intrusion Detection Systems, Hardware Security Modules, Privileged Account Security and the automation of the prevention, detection and response security controls.",
      score5: "Perfect score.",
      recommendation:
        "A partnership with KBC and Soluz.io would greatly increase your odds of gaining a level.",
    },
    GeneralScore: {
      score1:
        "In one sentence, security improving activities are uncoordinated or not a focus, there is no security program and only basic security controls are available. The hardest thing for the IT department to drive security improving activities is getting the buy-in of the management. Without this buy-in, the IT department can only do so much as the budget allows, resulting in a reactive approach, fixing the problems that occur.",
      score2:
        "The level 2 organization has a more or less mature perimeter and endpoint strategy. The IT department is probably doing the best they can to protect the organization against cyber-attacks.Improving the Cyber security is mostly a “people” matter. They need more End-user Security awareness and leadership in the management team in the form of an information security officer. Evolving from Security Maturity Level 2 to Security Maturity Level 3 requires a buy-in of the business owners and users, who need to fully understand the risks of a poor Cyber Security strategy to their responsibilities.",
      score3:
        "The level 3 organization is a well-protected fort with its own dynamic defense capabilities in terms of Cyber Security. The technologies, processes and people responsibilities are well balanced and it becomes difficult to attack these organizations without gaining inside information. Further improvement centers around user centric security controls and an optimization of the established processes.",
      recommendationOrange:
        "Overweeg de volgende acties: \u000a -zorg dat je een goed zicht krijgen op wat je te doen staat, maw WAAR zitten de security gaten. Met een technische scan krijg je dat beeld objectief in kaart gebracht. \u000a -inventariseer welke processen gekend, gevolgd worden door je medewerkers. Ontbrekende processen worden best zsm opgesteld in samenspraak met de betrokken personen/diensten. \u000a -Belangrijk is ook om een gedegen bewustwordingsprogramma op te stellen en te lanceren. Je medewerkers zijn je eerstelijnsdefensie.",
      recommendationRed:
        "We raden je met spoed aan om een techische scan van niet alleen je netwerkomgeving, desktop/laptop/servers maar ook van je applicaties uit te voeren zodat je als zaakvoerder weet WAAR je moet investeren. \u000a Vergeet niet, naast het technologisch aspect, ook te werken aan het verhogen van bewustzijn van de gevaren binnen de organisatie, alsook de nodige ondersteunende processen te implementeren.",
    },
    Report: {
      title: "Security Maturity Assessment",
      general: "General",
      score: "Score",
      recommendation: "Recommendation",
      recommendationButton: "Make an appointment with a specialist",
      appointmentKbc: "Would you like to talk to your KBC expert?",
      appointmentSoluzio:
        "Would you like to explore Soluzio's E-Invoicing solutions?",
      appointmentOrange:
        "Would you like to talk to a Cyber Security Expert at Orange Cyberdefense?",
      contact: "Contact us",
      kbccontact: "Neem contact op met ons agentschap",
    },
    Cookies: {
      message:
        "If you give your consent, this website uses cookies to map the use of the website and improve its content (analytical cookies)",
      click: "Read more about it in the cookie statement.",
      accept: "OK",
      ignore: "Ignore me",
      revoke: "Revoke cookie consent",
    },
    Policy: {
      title: "Privacy & Cookies Policy",
      privacyTitle: "Privacy Policy",
      cookiesTitle: "Cookies Policy",
      Privacy: {
        intro:
          "Our Privacy Policy was last updated on 13/04/2022. \u000a \u000a This Privacy Policy describes our policies and procedures on the collection, use and disclosure of your information when you use the service and tells you about your privacy rights and how the law protects you. \u000a \u000a We use your personal data to provide and improve the service. By using the service, you agree to the collection and use of information in accordance with this Privacy Policy.",
        s1q: "Types of Data collected",
        s1a: "Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Tracker; Usage Data. Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection. Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application. Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service. Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner. Any use of Cookies – or of other tracking tools – by this Application or by the owners of third-party services used by this Application serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available. Users are responsible for any third-party Personal Data obtained, published or shared through this Application and confirm that they have the third party's consent to provide the Data to the Owner.",
        s2title: "Mode and place of processing the Data",
        s21q: "Methods of processing",
        s21a: "The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data. The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Application (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.",
        s22q: "Legal basis of processing",
        s22a: "The Owner may process Personal Data relating to Users if one of the following applies:",
        s22a1li:
          "Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;",
        s22a2li:
          "provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;",
        s22a3li:
          "processing is necessary for compliance with a legal obligation to which the Owner is subject;",
        s22a4li:
          "processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;",
        s22a5li:
          "processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.",
        s22a2:
          "In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.",
        s23q: "Place",
        s23a: "The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located. \u000a \u000a Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data. If broader protection standards are applicable, Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data. \u000a \u000a If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.",
        s24q: "Retention time",
        s24a: "Personal Data shall be processed and stored for as long as required by the purpose they have been collected for. \u000a Therefore:",
        s24a1li:
          "Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.",
        s24a2li:
          "Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.",
        s24a2:
          "The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority. \u000a \u000a Once the retention period expires, Personal Data shall be deleted. Therefore, the right of access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.",
        s3q: "The purposes of processing",
        s3a: "The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as the following: Analytics. For specific information about the Personal Data used for each purpose, the User may refer to the section “Detailed information on the processing of Personal Data”.",
        s4q: "Detailed information on the processing of Personal Data",
        s4a: "Personal Data is collected for the following purposes and using the following services:",
        s4a1li: "Analytics",
        s5title: "The rights of Users",
        s51a: "Users may exercise certain rights regarding their Data processed by the Owner. \u000a Users entitled to broader protection standards may exercise any of the rights described below. In all other cases, Users may inquire with the Owner to find out which rights apply to them. \u000a In particular, Users have the right to do the following:",
        s51a1libold: "Withdraw their consent at any time.",
        s51a1li:
          "Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.",
        s51a2libold: "Object to processing of their Data.",
        s51a2li:
          "Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.",
        s51a3libold: "Access their Data.",
        s51a3li:
          "Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.",
        s51a4libold: "Verify and seek rectification.",
        s51a4li:
          "Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.",
        s51a5libold: "Restrict the processing of their Data.",
        s51a5li:
          "Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.",
        s51a6libold: "Have their Personal Data deleted or otherwise removed.",
        s51a6li:
          "Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.",
        s51a7libold:
          "Receive their Data and have it transferred to another controller.",
        s51a7li:
          "Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof.",
        s51a8libold: "Lodge a complaint.",
        s51a8li:
          "Users have the right to bring a claim before their competent data protection authority.",
        s52q: "Details about the right to object to processing",
        s52a: "Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection. Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.",
        s53q: "How to exercise these rights",
        s53a: "Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.",
        s6q: "Applicability of broader protection standards",
        s6a: "While most provisions of this document concern all Users, some provisions expressly only apply if the processing of Personal Data is subject to broader protection standards. Such broader protection standards apply when the processing:",
        s6a1li: "is performed by an Owner based within the EU;",
        s6a2li:
          "concerns the Personal Data of Users who are in the EU and is related to the offering of paid or unpaid goods or services, to such Users;",
        s6a3li:
          "concerns the Personal Data of Users who are in the EU and allows the Owner to monitor such Users’ behavior taking place in the EU.",
        s7title: "Additional information about Data collection and processing",
        s71q: "Legal action",
        s71a: "The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Application or the related Services. The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.",
        s72q: "Additional information about User's Personal Data",
        s72a: "In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.",
        s73q: "System logs and maintenance",
        s73a: "For operation and maintenance purposes, this Application and any third-party services may collect files that record interaction with this Application (System logs) use other Personal Data (such as the IP Address) for this purpose.",
        s74q: "Information not contained in this policy",
        s74a: "More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.",
        s75q: "How “Do Not Track” requests are handled",
        s75a: "This Application does not support “Do Not Track” requests. To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.",
        s76q: "Changes to this privacy policy",
        s76a: "The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on this page and possibly within this Application and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom. \u000a  \u000a Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the User, where required.",
        s8title: "Definitions and legal references",
        s81q: "Personal Data (or Data)",
        s81a: "Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.",
        s82q: "Usage Data",
        s82a: "Information collected automatically through this Application (or third-party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.",
        s83q: "User",
        s83a: "The individual using this Application who, unless otherwise specified, coincides with the Data Subject.",
        s84q: "Data Subject",
        s84a: "The natural person to whom the Personal Data refers.",
        s85q: "Data Processor (or Data Supervisor)",
        s85a: "The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.",
        s86q: "Data Controller (or Owner)",
        s86a: "The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.",
        s87q: "This Application",
        s87a: "The means by which the Personal Data of the User is collected and processed.",
        s88q: "Service",
        s88a: "The service provided by this Application as described on this site/application.",
        s89q: "European Union (or EU)",
        s89a: "Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.",
        s810q: "Cookie",
        s810a:
          "Cookies are Trackers consisting of small sets of data stored in the User's browser.",
        s811q: "Tracker",
        s811a:
          "Tracker indicates any technology - e.g Cookies, unique identifiers, web beacons, embedded scripts, e-tags and fingerprinting - that enables the tracking of Users, for example by accessing or storing information on the User’s device.",
        s9q: "Legal information",
        s9a: "This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation). This privacy policy relates solely to this Application, if not stated otherwise within this document.",
        s10q: "Neem contact op",
        s10a: "If you have any questions about this Privacy Policy, please contact us:",
        s10a1li: "By email: {mail}",
        s10a2li: "By phone {telefoon}",
        //s1q: "Definitions and legal references",
        //s1a: "For the purposes of this Privacy Policy:"
      },
      Cookies: {
        s1q: "Does cybersecuritytest.be use cookies?",
        s1a: "Yes. As described in the above Privacy Policy, we use cookies to ensure everyone who uses cybersecuritytest.be has the best possible experience. By continuing to visit or use our services, you are agreeing to the use of cookies and similar technologies for the purposes we describe in this policy.",
        s2q: "What are cookies?",
        s2a: "Cookies are small files placed on your device by a website you visit and that enable certain features and functionality for that website. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you. Cookies can be “persistent” or “session” cookies. Persistent cookies remain on your personal computer or mobile device when you go offline, while session cookies are deleted as soon as you close your web browser.",
        s3q: "When does cybersecuritytest.be place cookies?",
        s3a: "We use cookies on our site, any browser visiting this site will thus receive cookies from us if approved by the user.",
        s4q: "How does cybersecuritytest.be use cookies?",
        s4a: "When you use and access the Service, we may place a number of cookies files in your web browser. Currently, we only use cookies to provide analytics. We use analytics cookies to track information how the Service is used so that we can make improvements. We may also use analytics cookies to test new pages, features or new functionality of the Service to see how our users react to them.",
        s5q: "Third-party cookies",
        s5a: "In addition to our own cookies, we may also use various third-parties cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.",
        s5bq: "How Google Analytics Collects Data",
        s5ba: "Google Analytics uses cookies to remember a user's behavior and it shares these insights with you. The developers explain this process fully on their reference platform, but in simple terms, Google Analytics mainly uses first-party cookies to generate reports on who visits your website and what they do once they get there. When we break it down, this information is extremely useful for commercial purposes. For example, the collected data shows you where in the world your traffic is coming from, and whether you're appealing to your intended audience. \u000a \u000a For more information on how Google Analytics uses cookies, click",
        s5bahref:
          "https://developers.google.com/analytics/devguides/collection/gtagjs/cookie-usage",
        s5bahrefbtn: "here",
        s6q: "What are your choices regarding cookies?",
        s6a: "If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.",
        s6ali1:
          "For the Chrome web browser, please visit this page from Google: ",
        s6ali1href: "https://support.google.com/accounts/answer/32050",
        s6ali2:
          "For the Internet Explorer web browser, please visit this page from Microsoft: {a}",
        s6ali2href: "http://support.microsoft.com/kb/278835",
        s6ali3:
          "For the Firefox web browser, please visit this page from Mozilla: {a}",
        s6ali3href:
          "https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored",
        s6ali4:
          "For the Safari web browser, please visit this page from Apple: {a}",
        s6ali4href:
          "https://support.apple.com/sv-se/guide/safari/manage-cookies-and-website-data-sfri11471/mac",
        s6ali5:
          "For any other web browser, please visit your web browser's official web pages.",
        s7q: "Where can you find more information regarding cookies?",
        s7a: "You can learn more about cookies and the following third-party websites:",
        s7ali1: "AllAboutCookies:",
        s7ali1href: "http://www.allaboutcookies.org",
        s7ali2: "Learn about cookies:",
        s7ali2href: "https://www.learn-about-cookies.com",
        s7ali3: "Cookies and you:",
        s7ali3href: "https://www.cookiesandyou.com",
      },
    },
    NotFound: {
      error: "That's an error.",
      notfound: "The requested URL was not found on this server.",
      info: "That's all we know.",
    },
  },
  nl: {
    next: "Volgende",
    yes: "Ja",
    no: "Nee",
    unsure: "Onzeker",
    calculateScore: "Bereken score",
    step: "Stap {no} van 3",
    bigTitle: "Denk je dat je IT-omgeving goed beveiligd is?",
    smallTitle:
      "Deze test is een samenwerking tussen KBC, Soluzio en Orange Cyberdefence.",
    questionTitle:
      "Beantwoord onderstaande vragen om de {name} assessment te voltooien",
    moreInfo: "Voor meer informatie over deze beoordeling, bekijk de ",
    documentationHrefTitle: "documentatie",
    homeButton: "Doe de test",
    homeLongText:
      "We maken onze bedrijven steeds efficiënter door gebruik te maken van digitale systemen en processen. \u000a Verkopen via het internet, digitale aanwezigheid versterken, op afstand werken? Check! \u000a \u000a Ondertussen worden we wel afhankelijker van deze digitale systemen en hier zijn ook risico's aan verbonden. \u000a Niet enkel voor de interne werking maar ook voor de beveiliging van de persoonsgegevens die we opslaan dienen we ons te wapenen tegen nieuwe Cybercrime trends. \u000a \u000a Als bank verzekeraar, fintech en cybersecurity adviseur zijn we ons bewust van de continue dreiging en schenken we veel aandacht aan de beveiliging van onze omgevingen. \u000a Ben jij je ook bewust van de mogelijke gevaren en hoe je je hiertegen kan beveiligen? \u000a Zowel je organisatie (de mensen), je processen als de technologische tools waarmee je je beveiligt kunnen kwetsbaarheden vertonen. Met de nodige aandacht en organisatie kan je veel problemen voorkomen, want voorkomen is nog steeds beter dan genezen. \u000a \u000a De Cyber Security Test maakt je, aan de hand van 17 vragen, bewust van de verschillende aspecten van je bedrijf die met cybersecurity in aanraking kunnen komen en waar je als bedrijfsleider best even bij stilstaat. \u000a Maak gebruik van de tips en eventuele vervolgtrajecten om je cybersecurity bescherming naar een volgend niveau te brengen.",
    disclaimer:
      "Deze website en simulatie worden aangeboden door KBC Groep NV (met zetel te Havenlaan 2, 1080 Brussel, KBO 0403.227.515), Soluz.io NV (met zetel te Zwartzustersvest 24, 2800 Mechelen, KBO 0711.710.576) en Orange Cyberdefense NV (met zetel te Stokerijstraat 35, 2110 Wijnegem, BE 0479.419.926) (gezamenlijk de “Aanbieders”). \u000a De simulatie gebruikt een aantal parameters die kunnen wijzigen in de tijd en afhankelijk zijn van actuele inzichten op het gebied van cybersecurity en de stand van technologische ontwikkelingen. Het resultaat van de simulatie is louter informatief. De Aanbieders geven geen garanties wat betreft de actualiteit, accuraatheid, correctheid, volledigheid of geschiktheid voor een bepaald doel van de informatie die d.m.v. de simulatie wordt verstrekt. \u000a De aansprakelijkheid van de Aanbieders voor enigerlei schade die het gevolg is van de toegang tot, de consultatie of het gebruik van de informatie, gegevens en publicaties op deze website, kan alleen in het geval van opzet of grove fout van hun zijde worden ingeroepen. \u000a De intellectuele eigendomsrechten op de informatie, publicaties en gegevens verstrekt op deze website komen toe aan de Aanbieders, hetzij aan met hen verbonden entiteiten.",
    Index: {
      title:
        "Het duurt 5 minuten om een ​​paar belangrijke vragen te beantwoorden binnen de volgende categorieën van operationeel risico",
      people: "Mensen",
      process: "Processen",
      technology: "Technologie",
      score:
        "Na voltooiing krijgt u een score en inzicht in waardevolle aanbevelingen.",
    },
    People: {
      question1:
        "Werk je met een cyber security specialist (intern of extern via uw IT-partner)?",
      question2:
        "Simuleer je vanuit IT phishingmails naar je medewerkers om hen attent te maken op dit gevaar?",
      question3:
        "Heb je een Data Protection Officer aangesteld in kader van GDPR?",
      question4:
        "Is het voor jou als ondernemer makkelijk om cybersecurity-experten (vb: ethical hackers, pen-testers, ...) aan te trekken?",
      question5:
        "Simuleer je regelmatig cyberaanvallen? Dit is een oefening op papier waarbij jouw bedrijf slachtoffer is van hacking en oefent hoe snel je terug 'in business' geraakt? Vergelijkbaar met een halfjaarlijkse brandoefening.",
    },
    Process: {
      question1:
        "Verzend en ontvang je je facturen digitaal, op geautomatiseerde wijze en gebruik je hiervoor veilige kanalen? Het versturen en ontvangen van facturen via post of niet digitaal getekende pdf-facturen via e-mail is een werkwijze onderhevig aan factuur-fraude en is geen veilig kanaal om facturen uit te wisselen.",
      question2:
        "Ken je als ondernemer de cybersecurity risico's van je bedrijf? Weet je welke onderdelen/processen kwetsbaar zijn? Weet je bijvoorbeeld dat je productie kan uitvallen door een cyberaanval?",
      question3:
        "Ken jij als ondernemer de gevolgen van een cybersecurity aanval?",
      question4:
        "Is je bedrijf verzekerd voor de gevolgen van een cybersecurity aanval?",
      question5:
        "Heb je een controlemechanisme ingebouwd voor overschrijvingen/betalingen met hoge bedragen (bv Handtekeningsbevoegdheid waarbij 2 mensen moeten tekenen in de online banking).",
      question6:
        "Heb je een contract (rechtstreeks of via je IT-partner) met cybersecurity experten om je bij te staan in geval van een cyberaanval? (dat is meestal inbegrepen in een cybersecurity verzekering)",
    },
    Technology: {
      question1:
        "Zijn er procedures afgesproken/beschreven met de IT cybersecurity experten over opvolging en herstel van security bugs?",
      question2:
        "Beschik je over een cyber security monitoring (via je IT-partner of een security firma) om hackers proactief te detecteren wanneer ze je bedrijfsnetwerk aanvallen?",
      question3:
        "Weet je als ondernemer precies wie (werknemers, leveranciers, klanten, …) er allemaal toegang heeft tot jouw bedrijfsnetwerk?",
      question4:
        "Krijgen je medewerkers toegang tot je data en systemen (PC, mail, …) zonder two factor authentication? Dat is een authenticatie methode waarbij je twee stappen succesvol moet doorlopen om ergens toegang tot te krijgen.",
      question5:
        "Heb je software of andere technische beschermingsmaatregelen geïnstalleerd specifiek tegen diefstal van data?",
      question6:
        "Blokkeer je de toegang tot applicaties om bestanden te delen zoals Dropbox of Wetransfer?",
    },
    PeopleScore: {
      score1:
        "AANDACHT, u zit in de RODE zone! \u000a Daarmee bevindt u zich in de gevaren zone en is dringende actie vereist. \u000a \u000a De mens is het meest gebruikte doelwit in hacking aanvallen. Wanneer je rood kleurt op deze pijler betekent dat jouw onderneming eigenlijk de deuren heeft openstaan voor een willekeurige hacker. \u000a \u000a Weet dat de kosten om opnieuw op te starten veel meer inhouden dan enkel het losgeld dat je betaalt. Bijkomende kosten: niet alleen forensic IT onderzoek, heropstart (en eventuele fysieke schade), boetes (GDPR),  maar ook reputatieschade moet bij het totale kostenplaatje toegevoegd worden.",
      score2:
        "Opgelet, u zit in de oranje zone. \u000a Dat wil zeggen dat verschillende aspecten bij je medewerkers nog niet op punt staan. \u000a  \u000a Dit KAN je oplossen. Ofwel doe je dat zelf ofwel kunnen wij je daarmee helpen. In ieder geval is de belangrijkste raad hier: breng kennis over MET betrokkenheid. \u000a Trap niet in de val van het technische maar breng cybersecurity in mensentaal.",
      score3:
        "Proficiat! U zit in de groene zone. \u000a Dat betekent dat u uw medewerkers op vlak van Cybersecurity goed heeft opgeleid. \u000a \u000a Medewerkers helpen actief om cyberfraude op te sporen en te melden. Het blijft wel belangrijk om je medewerkers op regelmatige tijdstippen te testen. Op deze wijze blijven ze bij de pinken.",
      recommendationOrange: "",
      recommendationRed: "",
    },
    ProcessScore: {
      score1:
        "AANDACHT, u zit in de RODE zone! \u000a De organisatorische aspecten binnen uw organisatie verlopen duidelijk onvoldoende goed beheerst, waardoor er naast een cyberincident ook een serieus risico bestaat op boetes (o.a. inzake GDPR). \u000a \u000a Er is dringend actie nodig om een ernstig cyberincident te voorkomen. \u000a \u000a De beheersing van de IT-gerelateerde processen is slecht gestructureerd waardoor het ook voor (nieuwe) medewerkers onduidelijk is wat er van hen verwacht mag en kan worden.",
      score2:
        "Opgelet, u zit in de oranje zone. \u000a Het lijkt erop dat uw organisatorische aspecten ten dele beheerst verlopen. \u000a \u000a Er zitten toch een aantal duidelijke gaten in die best aangepakt worden om erger te voorkomen. \u000a \u000a Het is raadzaam om hier een diepere analyse op te laten doen. Wellicht zijn er hier en daar processen die wat bijschaving kunnen gebruiken. \u000a Mogelijks ontbreekt het ook aan een aantal processen. Het toepassen van een normenkader, bijvoorbeeld een ISO27001 standard, kan alvast helpen om gestructureerd aan de processen te werken.",
      score3:
        "Bravo! U zit in de groene zone. \u000a Het lijkt erop dat de operationele vereisten goed beheerst verlopen. Géén reden tot paniek, maar wel tot waakzaamheid. \u000a \u000a U kan hier steeds een assessment op laten uitvoeren om zeker te zijn of er toch nog hiaten zijn, maar het is niet uw eerste prioriteit.",
      recommendationOrange: "",
      recommendationRed: "",
    },
    TechnologyScore: {
      score1:
        "AANDACHT, u zit in de RODE zone! \u000a Je beveiliging is echt onvoldoende op dit moment, en maakt het de hackers veel te makkelijk.",
      score2:
        "Opgelet, u zit in de oranje zone. \u000a Er is géén echte reden tot paniek, maar we raden toch aan om actie te ondernemen. \u000a \u000a Er is nood aan een duidelijke visie en beleid op lange termijn. Hierdoor vermijd je dat er achterpoortjes ontstaan in je beveiliging, die mogelijks serieuze gevolgen kunnen hebben. \u000a \u000a Start met een technische scan van je omgeving. Deze gaat je helpen om een architectuur op te stellen die aan de huidige hoge beschermingseisen voldoet.",
      score3:
        "Perfect! Je hebt alle mogelijk beschikbare technologie in huis om je optimaal te beschermen. Wellicht handig om een formele jaarlijkse analyse van alle technische componenten uit te voeren. \u000a \u000a We raden aan om te starten met een grondige herziening van je netwerk en beveiligingscomponenten. Als deze niet onderhouden of tijdig hernieuwd worden, ben je een makkelijk doelwit voor hackers. \u000a \u000a Wacht niet tot morgen, neem vandaag nog contact op met specialisten om een begeleidingstraject te starten.",
      recommendationOrange: "",
      recommendationRed: "",
    },
    GeneralScore: {
      score1:
        "AANDACHT, u zit in de RODE zone! \u000a Daarmee bevindt u zich in de gevaren zone en is dringende actie vereist. \u000a \u000a Denk niet dat Hackers jou niet zullen komen zoeken. Hackers screenen alles en iedereen, en daar waar ze security gaten vinden, vallen ze binnen. \u000a Met deze score heb je werk aan de winkel want dit betekent dat je zwakheden in je ICT verdediging hebt die ze ZULLEN vinden.",
      score2:
        "Opgelet, u zit in de oranje zone. \u000a Dat betekent dat enkele fundamentele verdedigingslagen ofwel niet in orde zijn ofwel dat het door u als zaakvoerder niet geweten is hoe het ervoor staat. \u000a \u000a Dit KAN je oplossen en het hoeft echt allemaal niet onoverkomelijk veel te kosten. Door de prioriteiten met je ICT partner goed door te praten kan je de investering spreiden in de tijd.",
      score3:
        "Proficiat! U zit in de groene zone. \u000a Dat betekent dat u uw ICT zaken goed op orde heeft.",
      recommendationOrange:
        "Overweeg de volgende acties: \u000a -zorg dat je een goed zicht krijgen op wat je te doen staat, maw WAAR zitten de security gaten. Met een technische scan krijg je dat beeld objectief in kaart gebracht. \u000a -inventariseer welke processen gekend, gevolgd worden door je medewerkers. Ontbrekende processen worden best zsm opgesteld in samenspraak met de betrokken personen/diensten. \u000a -Belangrijk is ook om een gedegen bewustwordingsprogramma op te stellen en te lanceren. Je medewerkers zijn je eerstelijnsdefensie.",
      recommendationRed:
        "We raden je met spoed aan om een techische scan van niet alleen je netwerkomgeving, desktop/laptop/servers maar ook van je applicaties uit te voeren zodat je als zaakvoerder weet WAAR je moet investeren. \u000a Vergeet niet, naast het technologisch aspect, ook te werken aan het verhogen van bewustzijn van de gevaren binnen de organisatie, alsook de nodige ondersteunende processen te implementeren.",
    },
    Report: {
      title: "Security Maturity Assessment",
      general: "Algemeen resultaat",
      score: "Score",
      recommendation: "Aanbeveling",
      recommendationButton: "Maak een afspraak met een specialist",
      appointmentKbc: "Wil je graag een gesprek met je KBC verzekeringsexpert?",
      appointmentSoluzio:
        "Wilt u het eens hebben over de E-invoicing oplossingen van Soluzio?",
      appointmentOrange:
        "Wilt u praten met een Cyber Security Expert bij Orange Cyberdefense?",
      contact: "Contacteer ons",
      kbccontact: "Neem contact op met ons agentschap",
      moreQuestionsTitle: "Hebt u vragen over Cyber Security?",
      moreQuestionsTitleGreen:
        "Hebt u toch nog algemene vragen over Cyber Security?",
      contactSmall: "We contacteren je zo snel mogelijk",
      print: "Download rapport",
    },
    Cookies: {
      message:
        "Indien u daartoe uw toestemming geeft, maakt deze website gebruik van cookies voor het in kaart brengen van het gebruik van de website en het verbeteren van de inhoud ervan (analytische cookies).",
      click: "Lees er meer over in de cookieverklaring.",
      accept: "OK",
      ignore: "Liever niet",
      revoke: "Cookietoestemming intrekken",
    },
    Policy: {
      title: "Privacy & Cookies Policy",
      privacyTitle: "Privacy Policy",
      cookiesTitle: "Cookies Policy",
      Privacy: {
        intro:
          "Ons privacybeleid is voor het laatst bijgewerkt op 13/04/2022. \u000a \u000a Dit privacybeleid beschrijft ons beleid en onze procedures voor het verzamelen, gebruiken en vrijgeven van uw informatie wanneer u de service gebruikt en vertelt u over uw privacyrechten en hoe de wet beschermt u. \u000a \u000a We gebruiken uw persoonlijke gegevens om de service te leveren en te verbeteren. Door de service te gebruiken, gaat u akkoord met het verzamelen en gebruiken van informatie in overeenstemming met dit privacybeleid.",
        s1q: "Soort verzamelde gegevens",
        s1a: "Onder de soorten persoonlijke gegevens die deze applicatie zelf of via derden verzamelt, zijn er: Tracker; gebruiksgegevens. Volledige details over elk type verzamelde persoonlijke gegevens worden verstrekt in de speciale secties van dit privacybeleid of door specifieke uitlegteksten die voorafgaand aan de gegevensverzameling worden weergegeven. Persoonsgegevens kunnen vrijelijk door de gebruiker worden verstrekt of, in het geval van gebruiksgegevens, automatisch worden verzameld bij het gebruik van deze applicatie. Tenzij anders aangegeven, zijn alle door deze applicatie gevraagde gegevens verplicht en het niet het verstrekken van deze Gegevens kan het voor deze Applicatie onmogelijk maken om haar diensten te verlenen.In gevallen waarin deze Applicatie specifiek vermeldt dat sommige Gegevens niet verplicht zijn, staat het Gebruikers vrij om deze Gegevens niet mee te delen zonder gevolgen voor de beschikbaarheid of het functioneren van de Dienst. die niet zeker weten welke Persoonsgegevens verplicht zijn, kunnen contact opnemen met de Eigenaar.Elk gebruik van Cookies – of van andere trackingtools – door de is Applicatie of door de eigenaren van diensten van derden die door deze Applicatie worden gebruikt, dient om de door de Gebruiker vereiste Dienst te leveren, naast alle andere doeleinden die worden beschreven in dit document en in het Cookiebeleid, indien beschikbaar. Gebruikers zijn verantwoordelijk voor alle Persoonsgegevens van derden die zijn verkregen, gepubliceerd of gedeeld via deze Applicatie en bevestigen dat ze toestemming hebben van de derde partij om de Gegevens aan de Eigenaar te verstrekken.",
        s2title: "Modus en plaats van verwerking van de gegevens",
        s21q: "Verwerkingsmethoden",
        s21a: "De Eigenaar neemt passende beveiligingsmaatregelen om ongeoorloofde toegang, openbaarmaking, wijziging of ongeoorloofde vernietiging van de Gegevens te voorkomen. De gegevensverwerking wordt uitgevoerd met behulp van computers en/of IT-tools, volgens organisatorische procedures en modi die strikt verband houden met de doeleinden Naast de Eigenaar kunnen de Gegevens in sommige gevallen ook toegankelijk zijn voor bepaalde soorten verantwoordelijke personen die betrokken zijn bij de werking van deze Applicatie (administratie, verkoop, marketing, juridische zaken, systeembeheer) of externe partijen (zoals externe technische dienstverleners, postvervoerders, hostingproviders, IT-bedrijven, communicatiebureaus) die, indien nodig, door de eigenaar zijn aangesteld als gegevensverwerkers. De bijgewerkte lijst van deze partijen kan te allen tijde bij de eigenaar worden opgevraagd.",
        s22q: "Rechtsgrondslag voor verwerking",
        s22a: "De Eigenaar mag Persoonsgegevens met betrekking tot Gebruikers verwerken als een van de volgende situaties van toepassing is:",
        s22a1li:
          "Gebruikers hebben hun toestemming gegeven voor een of meer specifieke doeleinden. Opmerking: onder sommige wetgeving kan het de Eigenaar worden toegestaan ​​om Persoonsgegevens te verwerken totdat de Gebruiker bezwaar maakt tegen dergelijke verwerking ('opt-out'), zonder te hoeven vertrouwen op toestemming of enige andere andere van de volgende rechtsgrondslagen. Dit is echter niet van toepassing wanneer de verwerking van Persoonsgegevens onderworpen is aan de Europese wetgeving inzake gegevensbescherming;",
        s22a2li:
          "het verstrekken van Gegevens noodzakelijk is voor de uitvoering van een overeenkomst met de Gebruiker en/of voor eventuele precontractuele verplichtingen daarvan;",
        s22a3li:
          "de verwerking is noodzakelijk om te voldoen aan een wettelijke verplichting die op de Eigenaar rust;",
        s22a4li:
          "de verwerking heeft betrekking op een taak die wordt uitgevoerd in het algemeen belang of bij de uitoefening van openbaar gezag bij de Eigenaar;",
        s22a5li:
          "verwerking is noodzakelijk voor de behartiging van de legitieme belangen die worden nagestreefd door de Eigenaar of door een derde partij.",
        s22a2:
          "In ieder geval zal de Eigenaar graag helpen om de specifieke rechtsgrondslag die van toepassing is op de verwerking te verduidelijken, en in het bijzonder of het verstrekken van Persoonsgegevens een wettelijke of contractuele verplichting is, of een vereiste die nodig is om een ​​contract aan te gaan.",
        s23q: "Plaats",
        s23a: "De Gegevens worden verwerkt in de vestigingskantoren van de Eigenaar en op alle andere plaatsen waar de partijen die bij de verwerking betrokken zijn zich bevinden. Afhankelijk van de locatie van de Gebruiker kunnen gegevensoverdrachten de overdracht van de Gegevens van de Gebruiker naar een ander land dan Om meer te weten te komen over de plaats van verwerking van dergelijke overgedragen gegevens, kunnen gebruikers de sectie met details over de verwerking van persoonlijke gegevens raadplegen. Als er bredere beschermingsnormen van toepassing zijn, hebben gebruikers ook het recht om meer te weten te komen over de rechtsgrond van gegevens overdrachten naar een land buiten de Europese Unie of naar een internationale organisatie die onder internationaal publiekrecht valt of die is opgericht door twee of meer landen, zoals de VN, en over de veiligheidsmaatregelen die door de Eigenaar zijn genomen om hun Gegevens te beschermen. Als een dergelijke overdracht plaatsvindt, kunnen gebruikers meer te weten komen door de relevante secties van dit document te raadplegen of door contact op te nemen met de eigenaar met behulp van de informatie in het contact sectie.",
        s24q: "Retentietijd",
        s24a: "Persoonlijke gegevens worden verwerkt en bewaard zolang als nodig is voor het doel waarvoor ze zijn verzameld. \u000a Daarom:",
        s24a1li:
          "Persoonlijke gegevens die worden verzameld voor doeleinden die verband houden met de uitvoering van een contract tussen de Eigenaar en de Gebruiker, worden bewaard totdat een dergelijk contract volledig is uitgevoerd.",
        s24a2li:
          "Persoonlijke gegevens die worden verzameld met het oog op de legitieme belangen van de Eigenaar, worden bewaard zolang als nodig is om aan dergelijke doeleinden te voldoen. Gebruikers kunnen specifieke informatie vinden over de legitieme belangen die door de Eigenaar worden nagestreefd in de relevante secties van dit document of door contact op te nemen met de Eigenaar. ",
        s24a2:
          "De Eigenaar kan worden toegestaan ​​om Persoonsgegevens voor een langere periode te bewaren wanneer de Gebruiker toestemming heeft gegeven voor een dergelijke verwerking, zolang deze toestemming niet wordt ingetrokken. Bovendien kan de Eigenaar verplicht worden om Persoonsgegevens voor een langere periode te bewaren wanneer dat nodig is om dit te doen voor de uitvoering van een wettelijke verplichting of op bevel van een autoriteit. \u000a \u000a Zodra de bewaartermijn is verstreken, worden Persoonsgegevens verwijderd. Daarom zijn het recht op toegang, het recht op wissen, het recht op rectificatie en het recht op gegevensoverdraagbaarheid kan niet worden afgedwongen na het verstrijken van de bewaartermijn.",
        s3q: "De doeleinden van de verwerking",
        s3a: "De gegevens over de gebruiker worden verzameld om de eigenaar in staat te stellen zijn service te verlenen, te voldoen aan zijn wettelijke verplichtingen, te reageren op handhavingsverzoeken, zijn rechten en belangen (of die van zijn gebruikers of derden) te beschermen, kwaadwillende of frauduleuze activiteiten, evenals het volgende: Analyse. Voor specifieke informatie over de Persoonsgegevens die voor elk doel worden gebruikt, kan de Gebruiker verwijzen naar de sectie 'Gedetailleerde informatie over de verwerking van Persoonsgegevens'.",
        s4q: "Gedetailleerde informatie over de verwerking van persoonsgegevens",
        s4a: "Persoonlijke gegevens worden verzameld voor de volgende doeleinden en met behulp van de volgende diensten:",
        s4a1li: "Analyse",
        s5title: "De rechten van gebruikers",
        s51a: "Gebruikers kunnen bepaalde rechten uitoefenen met betrekking tot hun Gegevens die door de Eigenaar worden verwerkt. \u000a Gebruikers die recht hebben op bredere beschermingsnormen kunnen een van de hieronder beschreven rechten uitoefenen. In alle andere gevallen kunnen Gebruikers de Eigenaar informeren over welke rechten van toepassing zijn \u000a Gebruikers hebben in het bijzonder het recht om het volgende te doen:",
        s51a1libold: "Hun toestemming op elk moment intrekken.",
        s51a1li:
          "Gebruikers hebben het recht om toestemming in te trekken wanneer ze eerder hun toestemming hebben gegeven voor de verwerking van hun Persoonsgegevens.",
        s51a2libold: "Bezwaar tegen verwerking van hun gegevens.",
        s51a2li:
          "Gebruikers hebben het recht om bezwaar te maken tegen de verwerking van hun gegevens als de verwerking wordt uitgevoerd op een andere rechtsgrond dan toestemming. Meer details vindt u in het speciale gedeelte hieronder.",
        s51a3libold: "Toegang tot hun gegevens.",
        s51a3li:
          "Gebruikers hebben het recht om te weten te komen of Gegevens door de Eigenaar worden verwerkt, om openbaarmaking te verkrijgen over bepaalde aspecten van de verwerking en om een ​​kopie te krijgen van de Gegevens die worden verwerkt.",
        s51a4libold: "Controleer en zoek rectificatie.",
        s51a4li:
          "Gebruikers hebben het recht om de juistheid van hun gegevens te verifiëren en te vragen deze te updaten of te corrigeren.",
        s51a5libold: "Beperk de verwerking van hun gegevens.",
        s51a5li:
          "Gebruikers hebben onder bepaalde omstandigheden het recht om de verwerking van hun Gegevens te beperken. In dit geval zal de Eigenaar hun Gegevens voor geen enkel ander doel verwerken dan het opslaan ervan.",
        s51a6libold:
          "Hun persoonlijke gegevens laten verwijderen of anderszins verwijderen.",
        s51a6li:
          "Gebruikers hebben onder bepaalde omstandigheden het recht om het wissen van hun Gegevens van de Eigenaar te verkrijgen.",
        s51a7libold:
          "Ontvang hun gegevens en laat deze overdragen aan een andere verwerkingsverantwoordelijke.",
        s51a7li:
          "Gebruikers hebben het recht om hun Gegevens te ontvangen in een gestructureerd, algemeen gebruikt en machineleesbaar formaat en, indien technisch haalbaar, om deze ongehinderd door te sturen naar een andere verwerkingsverantwoordelijke. Deze bepaling is van toepassing op voorwaarde dat de Gegevens worden verwerkt met geautomatiseerde middelen en dat de verwerking is gebaseerd op de toestemming van de Gebruiker, op een contract waarvan de Gebruiker deel uitmaakt of op precontractuele verplichtingen daarvan.",
        s51a8libold: "Dien een klacht in.",
        s51a8li:
          "Gebruikers hebben het recht om een claim in te dienen bij hun bevoegde gegevensbeschermingsautoriteit.",
        s52q: "Details over het recht om bezwaar te maken tegen verwerking",
        s52a: "Wanneer persoonsgegevens worden verwerkt voor een openbaar belang, bij de uitoefening van een officieel gezag dat aan de eigenaar is verleend of voor de doeleinden van de legitieme belangen die door de eigenaar worden nagestreefd, kunnen gebruikers bezwaar maken tegen dergelijke verwerking door een grond op te geven die verband houdt met hun specifieke situatie om het bezwaar te rechtvaardigen.Gebruikers moeten echter weten dat, indien hun Persoonsgegevens worden verwerkt voor directmarketingdoeleinden, zij op elk moment bezwaar kunnen maken tegen die verwerking zonder enige rechtvaardiging.Om te weten te komen of de Eigenaar Persoonsgegevens verwerkt voor direct-marketingdoeleinden, kunnen gebruikers verwijzen naar de relevante secties van dit document.",
        s53q: "Hoe deze rechten uitoefenen",
        s53a: "Alle verzoeken om gebruiksrechten uit te oefenen kunnen worden gericht aan de Eigenaar via de contactgegevens in dit document. Deze verzoeken kunnen gratis worden uitgeoefend en zullen zo snel mogelijk en altijd binnen een maand door de Eigenaar worden behandeld.",
        s6q: "Toepasselijkheid van bredere beschermingsnormen",
        s6a: "Hoewel de meeste bepalingen van dit document alle Gebruikers betreffen, zijn sommige bepalingen uitdrukkelijk alleen van toepassing als de verwerking van Persoonsgegevens onderworpen is aan bredere beschermingsnormen. Dergelijke bredere beschermingsnormen zijn van toepassing wanneer de verwerking:",
        s6a1li: "wordt uitgevoerd door een Eigenaar gevestigd binnen de EU;",
        s6a2li:
          "betreft de persoonsgegevens van gebruikers die zich in de EU bevinden en is gerelateerd aan het aanbieden van betaalde of onbetaalde goederen of diensten aan dergelijke gebruikers;",
        s6a3li:
          "betreft de persoonlijke gegevens van gebruikers die zich in de EU bevinden en stelt de eigenaar in staat om het gedrag van dergelijke gebruikers in de EU te volgen.",
        s7title:
          "Aanvullende informatie over het verzamelen en verwerken van gegevens",
        s71q: "Juridische stappen",
        s71a: "De Persoonsgegevens van de Gebruiker kunnen door de Eigenaar voor juridische doeleinden worden gebruikt voor de rechtbank of in de fasen die leiden tot mogelijke juridische stappen als gevolg van oneigenlijk gebruik van deze Applicatie of de gerelateerde Diensten. De Gebruiker verklaart zich ervan bewust te zijn dat de Eigenaar verplicht om persoonsgegevens bekend te maken op verzoek van overheidsinstanties.",
        s72q: "Aanvullende informatie over de persoonlijke gegevens van de gebruiker",
        s72a: "Naast de informatie in dit privacybeleid, kan deze applicatie de gebruiker op verzoek aanvullende en contextuele informatie verstrekken over bepaalde services of het verzamelen en verwerken van persoonlijke gegevens.",
        s73q: "Systeemlogboeken en onderhoud",
        s73a: "Voor gebruiks- en onderhoudsdoeleinden kunnen deze Applicatie en alle diensten van derden bestanden verzamelen die de interactie met deze Applicatie vastleggen (systeemlogboeken) en hiervoor andere Persoonsgegevens (zoals het IP-adres) gebruiken.",
        s74q: "Informatie die niet in dit beleid is opgenomen",
        s74a: "Meer details over het verzamelen of verwerken van Persoonsgegevens kunnen te allen tijde bij de Eigenaar worden opgevraagd. Raadpleeg de contactgegevens aan het begin van dit document.",
        s75q: "Hoe 'Do Not Track'-verzoeken worden afgehandeld",
        s75a: "Deze applicatie ondersteunt geen 'Do Not Track'-verzoeken. Om te bepalen of een van de services van derden die het gebruikt de 'Do Not Track'-verzoeken honoreren, leest u hun privacybeleid.",
        s76q: "Wijzigingen in dit privacybeleid",
        s76a: "De Eigenaar behoudt zich het recht voor om te allen tijde wijzigingen aan te brengen in dit privacybeleid door zijn Gebruikers op deze pagina en mogelijk binnen deze Applicatie hiervan op de hoogte te stellen en/of - voor zover technisch en juridisch haalbaar - door een kennisgeving aan Gebruikers te sturen via elk contact informatie beschikbaar voor de Eigenaar. Het wordt ten zeerste aanbevolen om deze pagina vaak te controleren, verwijzend naar de datum van de laatste wijziging die onderaan staat vermeld. \u000a \u000a Als de wijzigingen van invloed zijn op verwerkingsactiviteiten die worden uitgevoerd op basis van toestemming van de Gebruiker, zal de Eigenaar zal indien nodig nieuwe toestemming van de Gebruiker vragen.",
        s8title: "Definities en juridische verwijzingen",
        s81q: "Persoonlijke gegevens (of gegevens)",
        s81a: "Alle informatie die direct, indirect of in verband met andere informatie - inclusief een persoonlijk identificatienummer - de identificatie of identificeerbaarheid van een natuurlijke persoon mogelijk maakt.",
        s82q: "Gebruiksgegevens",
        s82a: "Informatie die automatisch wordt verzameld via deze applicatie (of services van derden die in deze applicatie worden gebruikt), waaronder: de IP-adressen of domeinnamen van de computers die worden gebruikt door de gebruikers die deze applicatie gebruiken, de URI-adressen (Uniform Resource Identifier ), het tijdstip van het verzoek, de gebruikte methode om het verzoek bij de server in te dienen, de grootte van het als antwoord ontvangen bestand, de numerieke code die de status van het antwoord van de server aangeeft (succesvolle uitkomst, fout, enz.), het land van oorsprong, de kenmerken van de browser en het besturingssysteem dat door de Gebruiker wordt gebruikt, de verschillende tijddetails per bezoek (bijv. de volgorde van de bezochte pagina's en andere parameters over het besturingssysteem van het apparaat en/of de IT-omgeving van de gebruiker.",
        s83q: "Gebruiker",
        s83a: "De persoon die deze Applicatie gebruikt en die, tenzij anders aangegeven, samenvalt met de Betrokkene.",
        s84q: "Betrokkene",
        s84a: "De natuurlijke persoon op wie de Persoonsgegevens betrekking hebben.",
        s85q: "Gegevensverwerker (of gegevensbeheerder)",
        s85a: "De natuurlijke of rechtspersoon, overheidsinstantie, instantie of andere instantie die Persoonsgegevens verwerkt namens de Verwerkingsverantwoordelijke, zoals beschreven in dit privacybeleid.",
        s86q: "Verwerkingsverantwoordelijke (of Eigenaar)",
        s86a: "De natuurlijke of rechtspersoon, overheidsinstantie, dienst of andere instantie die, alleen of samen met anderen, het doel van en de middelen voor de verwerking van Persoonsgegevens vaststelt, met inbegrip van de beveiligingsmaatregelen met betrekking tot de werking en het gebruik van deze Applicatie. De De gegevensbeheerder is, tenzij anders aangegeven, de eigenaar van deze applicatie.",
        s87q: "Deze toepassing",
        s87a: "De manier waarop de Persoonsgegevens van de Gebruiker worden verzameld en verwerkt.",
        s88q: "Service",
        s88a: "De service die door deze applicatie wordt geleverd zoals beschreven op deze site/applicatie.",
        s89q: "Europese Unie (of EU)",
        s89a: "Tenzij anders aangegeven, omvatten alle verwijzingen in dit document naar de Europese Unie alle huidige lidstaten van de Europese Unie en de Europese Economische Ruimte.",
        s810q: "Cookie",
        s810a:
          "Cookies zijn Trackers die bestaan ​​uit kleine gegevenssets die zijn opgeslagen in de browser van de Gebruiker.",
        s811q: "Tracker",
        s811a:
          "Tracker geeft elke technologie aan - bijv. Cookies, unieke identificatiegegevens, webbakens, ingesloten scripts, e-tags en vingerafdrukken - die het volgen van Gebruikers mogelijk maakt, bijvoorbeeld door toegang te krijgen tot of informatie op te slaan op het apparaat van de Gebruiker.",
        s9q: "Juridische informatie",
        s9a: "Deze privacyverklaring is opgesteld op basis van bepalingen van meerdere wetgevingen, waaronder Art. 13/14 van Verordening (EU) 2016/679 (Algemene Verordening Gegevensbescherming). Dit privacybeleid heeft uitsluitend betrekking op deze Applicatie, tenzij anders vermeld in dit document.",
        s10q: "Neem contact op",
        s10a: "Als u vragen heeft over dit privacybeleid, neem dan contact met ons op:",
        s10a1li: "Via email: {mail}",
        s10a2li: "Via telefoon {telefoon}",
        //s1q: "Definities en juridische verwijzingen",
        //s1a: "Voor de doeleinden van dit privacybeleid:"
      },
      Cookies: {
        s1q: "Gebruikt cybersecuritytest.be cookies?",
        s1a: "Ja. Zoals beschreven in het bovenstaande privacybeleid, gebruiken we cookies om ervoor te zorgen dat iedereen die cybersecuritytest.be gebruikt de best mogelijke ervaring heeft. Door onze diensten te blijven bezoeken of gebruiken, gaat u akkoord met het gebruik van cookies en vergelijkbare technologieën voor de doeleinden die we in dit beleid beschrijven.",
        s2q: "Wat zijn cookies?",
        s2a: "Cookies zijn kleine bestanden die op uw apparaat worden geplaatst door een website die u bezoekt en die bepaalde functies en functionaliteit voor die website mogelijk maken. Een cookiebestand wordt opgeslagen in uw webbrowser en stelt de Service of een derde partij in staat u te herkennen en uw volgende bezoek gemakkelijker te maken en de Service nuttiger voor u te maken. Cookies kunnen 'permanente' of 'sessie' cookies zijn. Permanente cookies blijven op uw pc of mobiele apparaat staan ​​wanneer u offline gaat, terwijl sessiecookies worden verwijderd zodra u uw webbrowser sluit.",
        s3q: "Wanneer plaatst cybersecuritytest.be cookies?",
        s3a: "We gebruiken cookies op onze site, elke browser die deze site bezoekt, zal dus cookies van ons ontvangen indien goedgekeurd door de gebruiker.",
        s4q: "Hoe gebruikt cybersecuritytest.be cookies?",
        s4a: "Wanneer u de Service gebruikt en opent, kunnen we een aantal cookiebestanden in uw webbrowser plaatsen. Momenteel gebruiken we cookies alleen om analyses te bieden. We gebruiken analytische cookies om bij te houden hoe de Service wordt gebruikt, zodat we verbeteringen kunnen aanbrengen. We kunnen ook analytische cookies gebruiken om nieuwe pagina's, functies of nieuwe functionaliteit van de Service te testen om te zien hoe onze gebruikers erop reageren.",
        s5q: "Third-party cookies",
        s5a: "Naast onze eigen cookies, kunnen we ook verschillende cookies van derden gebruiken om gebruiksstatistieken van de Service te rapporteren, advertenties op en via de Service te leveren, enzovoort.",
        s5bq: "Hoe Google Analytics gegevens verzamelt",
        s5ba: "Google Analytics gebruikt cookies om het gedrag van een gebruiker te onthouden en deelt deze inzichten met u. De ontwikkelaars leggen dit proces volledig uit op hun referentieplatform, maar in eenvoudige bewoordingen gebruikt Google Analytics voornamelijk first-party cookies om rapporten te genereren over wie uw website bezoekt en wat ze doen zodra ze daar zijn aangekomen. Wanneer we het opsplitsen, is deze informatie uiterst nuttig voor commerciële doeleinden. De verzamelde gegevens laten u bijvoorbeeld zien waar ter wereld uw verkeer vandaan komt en of u uw beoogde doelgroep aanspreekt. \u000a \u000a Voor meer informatie over hoe Google Analytics cookies gebruikt, klik",
        s5bahref:
          "https://developers.google.com/analytics/devguides/collection/gtagjs/cookie-usage",
        s5bahrefbtn: "hier",
        s6q: "Wat zijn uw keuzes met betrekking tot cookies?",
        s6a: "Als je cookies wilt verwijderen of je webbrowser wilt instrueren om cookies te verwijderen of te weigeren, bezoek dan de helppagina's van je webbrowser.",
        s6ali1: "Ga voor de Chrome-webbrowser naar deze pagina van Google:",
        s6ali1href: "https://support.google.com/accounts/answer/32050",
        s6ali2:
          "Ga voor de webbrowser Internet Explorer naar deze pagina van Microsoft:",
        s6ali2href: "http://support.microsoft.com/kb/278835",
        s6ali3: "Ga voor de Firefox-webbrowser naar deze pagina van Mozilla:",
        s6ali3href:
          "https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored",
        s6ali4: "Ga voor de Safari-webbrowser naar deze pagina van Apple:",
        s6ali4href:
          "https://support.apple.com/sv-se/guide/safari/manage-cookies-and-website-data-sfri11471/mac",
        s6ali5:
          "Ga voor elke andere webbrowser naar de officiële webpagina's van uw webbrowser.",
        s7q: "Waar vind je meer informatie over cookies?",
        s7a: "U kunt meer te weten komen over cookies en de volgende websites van derden:",
        s7ali1: "AllAboutCookies:",
        s7ali1href: "http://www.allaboutcookies.org",
        s7ali2: "Learn about cookies:",
        s7ali2href: "https://www.learn-about-cookies.com",
        s7ali3: "Cookies and you:",
        s7ali3href: "https://www.cookiesandyou.com",
      },
    },
    NotFound: {
      error: "Dat is een fout.",
      notfound: "De gevraagde URL kon niet gevonden worden op deze server.",
      info: "Dat is alles wat we weten.",
    },
  },
  fr: {
    next: "Next",
    yes: "Oui",
    no: "Non",
    unsure: "Incertain",
    calculateScore: "Calculate score",
    step: "Étape {no} de 3",
    bigTitle: "Dans quelle mesure votre entreprise est-elle sécurisée ?",
    smallTitle:
      "Passez ce test d'évaluation de la sécurité et nous vous fournirons des conseils sur la façon d'améliorer votre cybersécurité et votre sensibilisation. Ce test est une collaboration entre KBC, Soluzio et Orange Cyberdefence.",
    questionTitle:
      "Veuillez répondre aux questions ci-dessous pour compléter l'évaluation de {name}",
    moreInfo:
      "For a guide or more information on this assessment, check out the ",
    documentationHrefTitle: "documentation",
    homeButton: "Faites le test",
    Index: {
      title:
        "Il faut 5 minutes pour parcourir quelques questions clés dans les familles de risques opérationnels suivantes",
      people: "Gens",
      process: "Processus",
      technology: "Technologie",
      score:
        "À la fin, vous obtiendrez un score et un aperçu des recommandations précieuses.",
    },
    People: {
      question1:
        "Does your IT team has a cybersecurity specialist (or is this outsourced to a partner)?",
      question2:
        "Does your company have a cybersecurity employee awareness program in place?",
      question3:
        "Does your company have a DPO (data protection officer), internal or external?",
      question4:
        "Is it difficult to recruit, retain IT / cybersecurity employees?",
      question5:
        "Does your IT staff rehearses disaster recovery scenario's at least once a year?",
    },
    Process: {
      question1:
        "Has your company digitized its business processes (e-invoicing, …)",
      question2: "As a CEO, do you know your cybersecurity risks?",
      question3:
        "Are you - as a CEO - comfortable how cyber incidents are handled in your company?",
      question4: "Does your company have a cybersecurity insurance?",
      question5:
        "Does your company have a CSIRT (cybersecurity incident response team) contract in place?",
    },
    Technology: {
      question1:
        "Does your company have visibility on what's happening in your network and cloud?",
      question2:
        "Is your company capable of proactive montoring and detection of cybersecurity incidents?",
      question3:
        "Does your company manage 3rd party access to its data and systems?",
      question4:
        "Does your current network and systems setup adheres to the Zero-trust philosophy?",
      question5: "Does your company use data loss prevention tools?",
    },
    PeopleScore: {
      score1:
        "In level 1 organizations IT is in charge for everything IT related, with little or no budget for security controls. The employees of the organization have no awareness on security as well: shadow IT, easy guessable passwords, unlocked computer systems, confidential information on desks, etc.",
      score2:
        "In most level 2 organizations the person responsible for IT Management gets Security as a secondary responsibility. Often the IT manager relies on another very technical decision maker within the organization to manage the security aspects of the organization. It is possible there is a lack of leadership in the management layer of the organization when it comes to security.",
      score3:
        "Most level 3 organizations have a dedicated IT security team with well-defined responsibilities for each member. Security awareness among users is well established and continuously improving. If the organization is doing in-house development, their developers start to organize themselves for a Secure Software Development Life Cycle.",
      score4:
        "In a level 4 organization all users are aware of the importance of security while the security team is organized to take the appropriate actions on any computer and security incident while regular tasks. However, the security aware users are the next liability for organizations that reached the fourth level.",
      score5: "Wow what a great score!",
      recommendation:
        "To go up a level we would strongly recommend looking into a partnership with Soluz.io",
    },
    ProcessScore: {
      score1:
        "The level 1 organization has no security policy or program, the incidents that happen usually impact the business activities. The IT-department has to fix them when they occur. ",
      score2:
        "Level 2 organizations have the basic security processes (partly) covered (asset inventories, patch management, disaster recovery, business continuity, …) and are aware on what is happening on the network and within their IT infrastructure. However, when incidents happen, knowing the cause is the result of a time-consuming root-cause analysis and they cannot solve it right away.",
      score3:
        "A level 3 organization has the basic security processes well established. The additional processes center around improving detection capabilities. The detection of vulnerabilities, security information and event logging while gaining control over internal threats as well. A periodic penetration test is conducted as a detection process. The results of the detection processes start to evolve into a periodic incident response process.",
      score4:
        "The security improving processes are well established and a continuous process for the security team. Security risk are assessed as an ongoing process and the detection capabilities grow into the user and entity behavior space. The user finds an extension into privileged account management and threats are shared with peers as a process. Organizations start with red (hackers) and blue (defenders) team exercises to simulate real-life Cyber Security scenario’s to be prepared.",
      score5: "Perfect score!",
      recommendation:
        "A partnership with KBC and Soluz.io would greatly increase your odds of gaining a level.",
    },
    TechnologyScore: {
      score1:
        "Typical security technologies you find in the IT environment of level 1 organizations are a firewall, a signature based anti-malware solution, an anti-spam solution in the cloud and probably a back-up solution that isn’t used on a regular basis. Remote access to the organization is typically done via Remote Desktop Services directly connected to the Internet or a simple firewall build-in SSL VPN.",
      score2:
        "Typical security technologies you find in the IT environment of level 2 organizations are a next-generation firewall, an anti-malware solution, an e-mail security solution, a back-up solution and possibly one or more of the following: a SSL VPN, a proxy, a reverse proxy, a separate WiFi-network for guests, segmentation on the network, (two-factor authentication), a high availability configuration over two data centers or maybe even a sandbox to detect 0-day threats. Most of the technologies are used to protect the perimeter and the endpoints, there is a lack in focus on the internal network, their systems and their data.",
      score3:
        "Typical security technologies in a level 3 organization focus on the internal network, the systems and the data. The security team is architecting and implementing the security controls while they are maintained by the IT department and monitored by the security team. The security technologies that are added in level 3 are SIEM, Segmentation Firewalls, Network Access Control, Identity and Access Management as well as the first steps to data discovery and classification technologies.",
      score4:
        "The technology focus for a level 4 organization is centered around (un-)intentional actions of users that compromise security or privacy. Technologies that monitor security configuration, Data Loss and Exfiltration, Intrusion Detection Systems, Hardware Security Modules, Privileged Account Security and the automation of the prevention, detection and response security controls.",
      score5: "Perfect score.",
      recommendation:
        "A partnership with KBC and Soluz.io would greatly increase your odds of gaining a level.",
    },
    GeneralScore: {
      score1:
        "In one sentence, security improving activities are uncoordinated or not a focus, there is no security program and only basic security controls are available. The hardest thing for the IT department to drive security improving activities is getting the buy-in of the management. Without this buy-in, the IT department can only do so much as the budget allows, resulting in a reactive approach, fixing the problems that occur.",
      score2:
        "The level 2 organization has a more or less mature perimeter and endpoint strategy. The IT department is probably doing the best they can to protect the organization against cyber-attacks.Improving the Cyber security is mostly a “people” matter. They need more End-user Security awareness and leadership in the management team in the form of an information security officer. Evolving from Security Maturity Level 2 to Security Maturity Level 3 requires a buy-in of the business owners and users, who need to fully understand the risks of a poor Cyber Security strategy to their responsibilities.",
      score3:
        "The level 3 organization is a well-protected fort with its own dynamic defense capabilities in terms of Cyber Security. The technologies, processes and people responsibilities are well balanced and it becomes difficult to attack these organizations without gaining inside information. Further improvement centers around user centric security controls and an optimization of the established processes.",
      recommendationOrange:
        "Overweeg de volgende acties: \u000a -zorg dat je een goed zicht krijgen op wat je te doen staat, maw WAAR zitten de security gaten. Met een technische scan krijg je dat beeld objectief in kaart gebracht. \u000a -inventariseer welke processen gekend, gevolgd worden door je medewerkers. Ontbrekende processen worden best zsm opgesteld in samenspraak met de betrokken personen/diensten. \u000a -Belangrijk is ook om een gedegen bewustwordingsprogramma op te stellen en te lanceren. Je medewerkers zijn je eerstelijnsdefensie.",
      recommendationRed:
        "We raden je met spoed aan om een techische scan van niet alleen je netwerkomgeving, desktop/laptop/servers maar ook van je applicaties uit te voeren zodat je als zaakvoerder weet WAAR je moet investeren. \u000a Vergeet niet, naast het technologisch aspect, ook te werken aan het verhogen van bewustzijn van de gevaren binnen de organisatie, alsook de nodige ondersteunende processen te implementeren.",
    },
    Report: {
      title: "Security Maturity Assessment",
      general: "Résultat global",
      score: "Score",
      recommendation: "Recommendation",
      recommendationButton: "Prendre rendez-vous avec un spécialiste",
      appointmentKbc:
        "Souhaitez-vous prendre rendez-vous avec votre expert KBC ?",
      appointmentSoluzio:
        "Souhaitez vous découvrir les solutions E-Invoicing de Soluzio ?",
      appointmentOrange:
        "Voulez-vous parler à un Expert Cyber ​​Sécurité chez Orange Cyberdefense ?",
      contact: "Contactez-nous",
    },
  },
  de: {
    next: "Nächste",
    yes: "Ja",
    no: "Nein",
    unsure: "Unsicher",
    calculateScore: "Punktzahl berechnen",
    step: "Schritt {no} von 3",
    bigTitle: "Wie sicher ist Ihr Unternehmen?",
    smallTitle:
      "Dieser Test ist eine Zusammenarbeit zwischen KBC, Soluzio und Orange Cyberdefence.",
    questionTitle:
      "Bitte beantworten Sie die folgenden Fragen, um die Bewertung von {name} abzuschließen",
    moreInfo:
      "For a guide or more information on this assessment, check out the ",
    documentationHrefTitle: "documentation",
    homeButton: "Starte den Test",
    homeLongText:
      "Durch den Einsatz digitaler Systeme und Prozesse machen wir unsere Unternehmen immer effizienter. Über das Internet verkaufen, die digitale Präsenz stärken, Telearbeit leisten? Check! Mittlerweile werden wir jedoch immer mehr von diesen digitalen Systemen abhängig und damit sind auch Risiken verbunden. \u000a \u000a Nicht nur für den internen Betrieb, sondern auch für die Sicherheit der von uns gespeicherten personenbezogenen Daten müssen wir uns gegen neue Trends in der Cyberkriminalität wappnen. Als Bank Versicherer, Fintech und Cybersicherheitsberater sind wir uns der ständigen Bedrohung bewusst und achten wir sehr auf die Sicherheit unserer Umgebungen. Kennen Sie auch die möglichen Gefahren und wie Sie sich davor schützen können? Sowohl Ihre Organisation (die Personen ), Ihre Prozesse als auch die technologischen Hilfsmittel, mit denen Sie sich schützen, können Schwachstellen aufweisen. \u000a \u000a Mit der nötigen Aufmerksamkeit und der richtigen Organisation können Sie vielen Problemen vorbeugen, denn Vorbeugen ist besser als Heilen. Mit Hilfe des Cyber Security Tests werden Sie sich anhand 17 Fragen bewusst von den verschiedenen Aspekten Ihres Unternehmens, die mit Cybersicherheit zu tun haben können und worüber Sie als Geschäftsführer einen Moment nachdenken sollten. Nutzen Sie die Tipps und die möglichen Folgemaßnahmen, um Ihre Cybersicherheit auf die nächste Stufe zu heben.",
    disclaimer:
      "Diese Website und die Simulation werden von KBC Gruppe AG (mit Sitz in Havenlaan 2, 1080 Brüssel, ZUD 0403.227.515), Soluz.io NV (mit Sitz in Zwartzustersvest 24, 2800 Mechelen, ZUD 0711.710.576) und Orange Cyberdefense NV (mit Sitz in Stokerijstraat 35, 2110 Wijnegem BE 0479.419.926) (zusammen die „Anbieter“) bereitgestellt. \u000a Die Simulation verwendet eine Reihe von Parametern, die sich im Laufe der Zeit ändern können und von den aktuellen Erkenntnissen im Bereich der Cybersicherheit und dem Stand der technischen Entwicklung abhängen. Das Ergebnis der Simulation ist rein informatorisch. Die Anbieter übernehmen keine Gewähr für die Aktualität, Richtigkeit, Genauigkeit, Vollständigkeit oder Eignung für einen bestimmten Zweck der mittels der Simulation bereitgestellten Informationen. \u000a Die Haftung der Anbieter für Schäden, die sich aus dem Zugriff, der Nutzung oder der Verwendung der Informationen, Daten und Publikationen auf dieser Website ergeben, kann nur bei Vorsatz oder grober Fahrlässigkeit geltend gemacht werden. \u000a Die Rechte am geistigen Eigentum der auf dieser Website bereitgestellten Informationen, Veröffentlichungen und Daten liegen bei den Anbietern oder den mit ihnen verbundenen Unternehmen.",
    Index: {
      title:
        "Es dauert 5 Minuten, um einige Schlüsselfragen innerhalb der folgenden Familien von operationellen Risiken durchzugehen",
      people: "Menschen",
      process: "Prozess",
      technology: "Technologie",
      score:
        "Nach Abschluss erhalten Sie eine Punktzahl und einen Einblick in wertvolle Empfehlungen.",
    },
    People: {
      question1:
        "Haben Sie einen Cybersicherheitsspezialisten im Unternehmen (oder extern über Ihren IT-Partner)?",
      question2:
        "Führen Sie mit Ihren Mitarbeitern Phishing-Simulationen durch (nachgeahmte Phishing-Mails, die von Ihrer IT-Abteilung zur Übung an Ihre Mitarbeiter geschickt werden)?",
      question3:
        "Hat Ihr Unternehmen einen Datenschutzbeauftragten ernannt im Zusammenhang mit der DSGVO?",
      question4:
        "Fällt es Ihnen als Unternehmer leicht, Cybersicherheitsexperten (z. B. ethische Hacker, Pen-Tester, ...) zu gewinnen?",
      question5:
        "Führen Sie Simulationen von Cyberangriffen durch (eine Übung auf Papier, bei der Sie sich als Opfer eines Hackerangriffs darstellen und üben, wie schnell Sie wieder operativ sind). Ähnlich wie Ihre halbjährliche Brandschutzübung.",
    },
    Process: {
      question1:
        "Versenden und empfangen Sie Rechnungen digital und automatisiert und nutzen Sie dafür sichere Kanäle? Der Versand und Empfang von Rechnungen auf dem Postweg oder von nicht digital signierten PDF-Rechnungen per E-Mail ist anfällig für Rechnungsbetrug und stellt keinen sicheren Kanal für den Austausch von Rechnungen dar.",
      question2:
        "Kennen Sie als Unternehmer Ihre Cybersicherheitsrisiken? Mit anderen Worten: Wissen Sie, welche Teile/Prozesse Ihres Unternehmens anfällig sind? Wissen Sie zum Beispiel, ob Ihre Produktion durch einen Cyberangriff lahmgelegt werden kann?",
      question3:
        "Kennen Sie als Unternehmer die Folgen eines Angriffs auf die Cybersicherheit?",
      question4:
        "Ist Ihr Unternehmen gegen die Folgen eines Angriffs auf die Cybersicherheit versichert?",
      question5:
        "Haben Sie ein 4-Augen-Prinzip für Überweisungen/Zahlungen mit hohen Beträgen eingebaut (z. B. zwei Personen müssen im Onlinebanking unterschreiben).",
      question6:
        "Haben Sie einen Vertrag (direkt oder über Ihren IT-Partner) mit Experten, die Ihnen im Falle eines Cyberangriffs helfen? (Das ist normalerweise in einer Cybersicherheitsversicherung enthalten)",
    },
    Technology: {
      question1:
        "Haben Sie sich mit Ihrem(n) IT-Partner(n) oder IT-Cybersicherheitsexperten auf einen Zeitrahmen für die Nachverfolgung und Behebung (Schließung) von Sicherheitslücken geeinigt?",
      question2:
        "Verfügen Sie über eine Überwachung der Cybersicherheit (durch Ihren IT-Partner oder ein Sicherheitsunternehmen), um Hacker zu erkennen, wenn sie in Ihrem Unternehmensnetz aktiv sind? Mit anderen Worten: Sind Sie in der Lage, Hacker in Ihrem Unternehmensnetzwerk proaktiv zu erkennen?",
      question3:
        "Haben Sie als Unternehmer einen Überblick darüber, wer (Mitarbeiter, Lieferanten, Kunden usw.) Zugang zu Ihrem Netz hat?",
      question4:
        "Haben Sie 2-Faktor-Authentifizierung für den Zugriff auf Ihre Daten und Systeme (PC, E-Mail, ...) aktiviert? Verwenden Sie nur Benutzerkennung und Passwort, um Zugang zu erhalten, oder kombinieren Sie diese mit anderen Methoden (z.B. Gesichtserkennung, SMS-Code ...)?",
      question5:
        "Haben Sie eine Software oder andere technische Schutzmaßnahmen (insbesondere gegen Datendiebstahl) installiert?",
      question6:
        "Blockieren Sie den Zugang zu die gemeinsame Nutzung von Daten über Dropbox oder WeTransfer?",
    },
    PeopleScore: {
      score3:
        "Herzlichen Glückwunsch! Sie befinden sich im grünen Bereich. \u000a Das bedeutet, dass Sie Ihre Mitarbeiter auf dem Gebiet der Cybersicherheit gut geschult haben. \u000a \u000a Die Mitarbeiter helfen aktiv bei der Aufdeckung und Meldung von Cyberbetrug. Dennoch ist es wichtig, dass Sie Ihre Mitarbeiter in regelmäßigen Abständen testen. Auf diese Weise bleiben sie auf dem Laufenden.",
      score2:
        "Achtung, Sie befinden sich im gelben Bereich. \u000a Dies bedeutet, dass verschiedene Aspekte bei Ihren Mitarbeitern noch nicht endgültig geklärt sind. \u000a \u000a Sie KÖNNEN das Problem lösen. Entweder Sie machen das selbst oder wir helfen Ihnen dabei. Der wichtigste Ratschlag lautet hier auf jeden Fall: Wissen an Engagement koppeln. \u000a \u000a Tappen Sie nicht in die Falle des rein technischen Ansatzes, sondern formulieren Sie Cybersicherheit in menschlicher Sprache.",
      score1:
        "ACHTUNG, Sie befinden sich im ROTEN Bereich! \u000a Sie befinden sich also in der Gefahrenzone und es besteht dringender Handlungsbedarf. \u000a \u000a Menschen sind das häufigste Ziel von Hackerangriffen. Wenn Sie hier im roten Bereich sind, bedeutet dies, dass in Ihrem Unternehmen Hackern die Türen offen stehen. \u000a \u000a Sie müssen sich bewusst sein, dass die Kosten für einen Neustart viel mehr umfassen als das Lösegeld, das Sie zahlen. Zusätzliche Kosten: Zu den Gesamtkosten müssen nicht nur die forensische IT-Untersuchung, der Neustart (und mögliche materielle Schäden) und Geldstrafen (DSGVO), sondern auch die Rufschädigung hinzugerechnet werden.",
    },
    ProcessScore: {
      score3:
        "Bravo! Sie befinden sich im gelben Bereich. \u000a Es scheint, dass die betrieblichen Anforderungen gut unter Kontrolle sind. Kein Grund zur Panik, aber man muss wachsam sein. \u000a \u000a Sie können jederzeit ein Assessment durchführen lassen, um zu prüfen, ob es noch Lücken gibt, aber das ist nicht Ihre erste Priorität.",
      score2:
        "Achtung, Sie befinden sich im gelben Bereich. \u000a Ihre organisatorischen Aspekte sind zum Teil unter Kontrolle. \u000a \u000a Es gibt einige offensichtliche Lücken, die behoben werden sollten, um Schlimmeres zu verhindern. \u000a \u000a Es ist ratsam, hier eine genauere Analyse vornehmen zu lassen. Vielleicht gibt es hier und da Prozesse, an denen man noch etwas feilen könnte. \u000a \u000a Möglicherweise fehlt es auch an einer Reihe von Prozessen. Die Anwendung eines Normenrahmens, z.B. einer ISO27001-Norm, kann bereits helfen, die Prozesse strukturiert zu bearbeiten.",
      score1:
        "ACHTUNG, Sie befinden sich im ROTEN Bereich! \u000a Die organisatorischen Aspekte innerhalb Ihrer Organisation sind eindeutig unzureichend unter Kontrolle, was bedeutet, dass neben einem Cybervorfall auch ein ernsthaftes Risiko von Geldstrafen (z. B. in Bezug auf die DSGVO) besteht. \u000a  \u000a Zudem ist die Steuerung der IT-bezogenen Prozesse nicht gut strukturiert, so dass für (neue) Mitarbeiter unklar ist, was von ihnen erwartet werden darf und kann. \u000a  \u000a Es besteht dringender Handlungsbedarf, um einen schweren Cybervorfall zu verhindern.",
    },
    TechnologyScore: {
      score3:
        "Perfekt! \u000a Sie haben alle technischen Möglichkeiten, um sich zu schützen. \u000a  \u000a Es könnte sinnvoll sein, eine formelle jährliche Analyse aller technischen Komponenten durchzuführen. So können Sie sicher sein, dass Sie optimal geschützt sind und bleiben.",
      score2:
        "Achtung, Sie befinden sich im gelben Bereich. \u000a Es besteht zwar kein Grund zur Panik, aber wir empfehlen dennoch, Maßnahmen zu ergreifen. \u000a \u000a Es bedarf langfristiger Konzepte und Strategien. Dadurch werden Sicherheitslücken vermieden, die schwerwiegende Folgen haben könnten.\u000a \u000a Beginnen Sie mit einer technischen Prüfung Ihrer Umgebung. Dies wird Ihnen helfen, eine Architektur zu schaffen, die den heutigen hohen Schutzanforderungen entspricht.",
      score1:
        " ACHTUNG, Sie befinden sich im ROTEN Bereich! \u000a Ihre Sicherheit ist im Moment wirklich unzureichend, Sie machen es Hackern viel zu leicht. \u000a \u000a Wir empfehlen, mit einer gründlichen Überprüfung Ihrer Netzwerk- und Sicherheitskomponenten zu beginnen. Wenn diese nicht rechtzeitig gewartet oder erneuert werden, sind Sie ein leichtes Ziel für Hacker. \u000a \u000a Warten Sie nicht bis morgen, sondern wenden Sie sich noch heute an unsere Spezialisten, um einen Beratungsprozess zu starten.",
    },
    GeneralScore: {
      score3:
        "Herzlichen Glückwunsch! Sie befinden sich im grünen Bereich. \u000a Das bedeutet, dass die ICT-Angelegenheiten bei Ihnen in Ordnung sind. \u000a \u000a Ziehen Sie die folgenden Maßnahmen in Betracht:\u000a * Durch ein Assessment, das von einer Drittpartei vorgenommen wird, wird sichergestellt, dass die Situation tatsächlich so ist, wie Sie sie selbst einschätzen. Das ist kein Misstrauensvotum gegenüber Ihren ICT-Mitarbeitern, sondern ein gesundes Vier-Augen-Prinzip.\u000a * Abschluss einer Cybersecurity-Versicherung\u000a * Testen Sie die Reaktion Ihrer Mitarbeiter, z. B. durch Phishing-Kampagnen, Mystery Calls ...\u000a * Setzen Sie ethische Hacker ein, um die letzten Schwachstellen in Ihrer Verteidigung zu finden und zu schließen (wiederholen Sie dies mindestens einmal jährlich).",
      score2:
        "Achtung, Sie befinden sich im gelben Bereich. \u000a Das bedeutet, dass entweder einige grundlegende Schutzmechanismen nicht vorhanden sind oder Sie als Geschäftsführer nicht wissen, wie die Dinge stehen. \u000a \u000a Sie können dieses Problem lösen und es muss auch nicht viel kosten. Wenn Sie die Prioritäten mit Ihrem ICT-Partner besprechen, können Sie die Investitionen über einen längeren Zeitraum verteilen.",
      score1:
        "ACHTUNG, Sie befinden sich im ROTEN Bereich! \u000a Sie befinden sich also in der Gefahrenzone und es besteht dringender Handlungsbedarf. \u000a \u000a Gehen Sie nicht davon aus, dass Hacker sich nicht für Sie interessieren werden. Hacker durchleuchten alles und jeden, und wo sie Sicherheitslücken finden, dringen sie ein. \u000a \u000a Mit diesem Ergebnis gibt es viel für Sie zu tun, denn es bedeutet, dass Sie Schwachstellen in Ihrer ICT-Verteidigung haben, die sie finden WERDEN.",
      recommendationOrange:
        "Ziehen Sie die folgenden Maßnahmen in Betracht: \u000a * Vergewissern Sie sich, dass Sie eine gute Vorstellung davon haben, was zu tun ist, also WO sich die Sicherheitslücken befinden. Ein technischer Scan wird Ihnen ein objektives Bild davon vermitteln. \u000a * Machen Sie eine Bestandsaufnahme, welche Verfahren Ihren Mitarbeitern bekannt sind und von ihnen befolgt werden. Fehlende Prozesse sollten so bald wie möglich in Absprache mit den betroffenen Personen/Diensten ausgearbeitet werden. \u000a * Außerdem ist es wichtig, ein fundiertes Sensibilisierungsprogramm auszuarbeiten und durchzuführen. Ihre Mitarbeiter sind Ihre erste Verteidigungslinie.",
      recommendationRed:
        "Wir empfehlen Ihnen dringend, nicht nur Ihre Netzwerkumgebung, Ihre Desktops/Laptops/Server, sondern auch Ihre Anwendungen technisch zu überprüfen, damit Sie als Unternehmer wissen, wo Sie investieren sollten. \u000a \u000a Neben dem technologischen Aspekt sollten Sie nicht vergessen, das Bewusstsein für die Gefahren innerhalb der Organisation zu schärfen und die notwendigen unterstützenden Prozesse zu implementieren.",
    },
    Report: {
      title: "Security Maturity Assessment",
      general: "Gesamtergebnis",
      score: "Score",
      recommendation: "Recommendation",
      recommendationButton:
        "Vereinbaren Sie einen Termin mit einem Spezialisten",
      appointmentKbc: "Möchten Sie mit Ihrem KBC-Versicherungsexperte sprechen?",
      appointmentSoluzio:
        "Möchten Sie die E-Invoicing-Lösungen von Soluzio kennenlernen?",
      appointmentOrange:
        "Möchten Sie mit einem Cyber-Sicherheitsexperten von Orange Cyberdefense sprechen?",
      contact: "Kontaktiere uns",
      kbccontact: "Kontaktieren Sie unsere Agentur",
      moreQuestionsTitle: "Haben Sie Fragen zum Thema Cyber ​​Security?",
      moreQuestionsTitleGreen:
        "Sie haben noch allgemeine Fragen zum Thema Cyber ​​Security?",
      contactSmall:
        "Wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen",
      print: "Bericht herunterladen",
    },
    Cookies: {
      message:
        "Wenn Sie Ihre Zustimmung geben, verwendet diese Website Cookies, um die Nutzung der Website abzubilden und deren Inhalt zu verbessern (analytische Cookies).",
      click: "Lesen Sie mehr darüber in der Cookie-Erklärung.",
      accept: "Annehmen",
      ignore: "Lieber nicht",
    },
    NotFound: {
      error: "Das ist ein Fehler.",
      notfound: "Die angeforderte URL wurde auf diesem Server nicht gefunden.",
      info: "Das ist alles was wir wissen.",
    },
  },
};

function getLocale() {
  var loc = "nl";
  return loc;
}

export default new VueI18n({
  locale: getLocale(),
  messages,
});
