import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  initialHistoryLength: 0,
  documentReferrer: "",
  peopleScore: 0,
  processScore: 0,
  technologyScore: 0,
};
const getters = {
  initialHistoryLength: (state) => state.initialHistoryLength,
  documentReferrer: (state) => state.documentReferrer,
  peopleScore: (state) => state.peopleScore,
  processScore: (state) => state.processScore,
  technologyScore: (state) => state.technologyScore,
};
const mutations = {
  SET_HISTORYLENGTH(state, length) {
    state.initialHistoryLength = length;
  },
  SET_DOCUMENTREFERRER(state, referrer) {
    state.documentReferrer = referrer;
  },
  SET_PEOPLESCORE(state, score) {
    state.peopleScore = score;
  },
  SET_PROCESSSCORE(state, score) {
    state.processScore = score;
  },
  SET_TECHNOLOGYSCORE(state, score) {
    state.technologyScore = score;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  actions: {},
  modules: {},
  getters,
});
