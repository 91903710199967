<template>
  <div id="app">
    <div id="logoHeader">
      <div id="logoHeaderLogo">
        <img
          alt="SAM logo"
          id="sam-logo"
          src="@/assets/sam-noname.png"
          @click="goHome()"
        />
      </div>
      <div id="logoHeaderName" class="font-bold">Cyber Security Test</div>
      <div id="logoHeaderLanguage">
        <LanguageBar />
      </div>
      <div class="clearfix"></div>
    </div>
    <keep-alive>
      <router-view />
    </keep-alive>
    <!--
    <cookie-law
      id="cookie-banner"
      ref="cookieBanner"
      v-on:accept="enableTracking()"
    >
      <div id="cb-content-wrapper" slot-scope="props">
        <div class="cb-content">
          <div id="cb-column-text">
            {{ $t("Cookies.message") }}
            <router-link to="policy">{{ $t("Cookies.click") }}</router-link>
          </div>
          <div id="cb-column-buttons">
            <button @click="props.close">
              <span>{{ $t("Cookies.ignore") }}</span>
            </button>
            <button @click="props.accept">
              <span>{{ $t("Cookies.accept") }}</span>
            </button>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </cookie-law>
    <div id="footer">
      <button
        class="kbc-href fake-href-btn"
        v-if="this.accepted == true"
        @click="disableTracking()"
      >
        {{ $t("Cookies.revoke") }}
      </button>
      <div class="clearfix"></div>
    </div>
    -->
  </div>
</template>

<script>
import LanguageBar from "@/components/LanguageBar.vue";
//import CookieLaw from "vue-cookie-law";

export default {
  name: "App",
  components: {
    LanguageBar,
    //CookieLaw,
  },
  data() {
    return {
      accepted: false,
    };
  },
  computed: {
    areCookiesAccepted() {
      return this.$refs.cookieBanner.isAccepted();
    },
  },
  mounted() {
    //this.accepted = this.areCookiesAccepted;
  },
  methods: {
    goHome() {
      this.$router.push({ path: "/" });
    },
    enableTracking() {
      this.accepted = true;
      if (this.$ga == undefined) {
        this.$nextTick(function () {
          this.$ga.enable();
        });
      } else {
        this.$ga.enable();
      }
    },
    disableTracking() {
      this.$refs.cookieBanner.revoke();
      this.accepted = false;
      this.$ga.disable();
    },
  },
};
</script>

<style>
@font-face {
  font-family: "MuseoSans Light";
  src: local("MuseoSans Light"),
    url(../public/fonts/MuseoSans/MuseoSans-100.otf) format("opentype");
}
@font-face {
  font-family: "MuseoSans";
  src: local("MuseoSans"),
    url(../public/fonts/MuseoSans/MuseoSans-300.otf) format("opentype");
}
@font-face {
  font-family: "MuseoSans Regular";
  src: local("MuseoSans Regular"),
    url(../public/fonts/MuseoSans/MuseoSans-500.otf) format("opentype");
}
@font-face {
  font-family: "MuseoSans Bold";
  src: local("MuseoSans Bold"),
    url(../public/fonts/MuseoSans/MuseoSans-700.otf) format("opentype");
}
.font-light {
  font-family: "MuseoSans Light";
}
.font-normal {
  font-family: "MuseoSans";
}
.font-regular {
  font-family: "MuseoSans Regular";
}
.font-bold {
  font-family: "MuseoSans Bold";
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
*:focus-visible {
  outline: none;
}
*:focus {
  outline: none;
  -webkit-tap-highlight-color: transparent !important;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.content {
  width: 70%;
  margin: 0 auto;
}

#logoHeaderLogo,
#logoHeaderLanguage,
#logoHeaderName {
  float: left;
}
#logoHeaderLogo,
#logoHeaderLanguage {
  width: 20%;
}
#logoHeaderLogo #sam-logo {
  cursor: pointer;
  width: 48px;
  height: 48px;
  margin-left: 15px;
}
#logoHeaderLogo,
#logoHeaderName,
#logoHeaderLanguage {
  display: flex;
  align-items: center;
  height: 82px;
}
#logoHeaderName {
  font-size: 20px;
  width: 60%;
  color: rgb(0, 54, 101);
  justify-content: center;
}
#logoHeaderLanguage {
  justify-content: end;
}
#app {
  font-family: "MuseoSans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: left;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
#cookie-banner #cb-content-wrapper {
  width: 100%;
}
#cookie-banner .cb-content {
  display: flex;
  align-items: center;
}
#cookie-banner #cb-column-text {
  flex: 0.7 0.7 0;
}
#cookie-banner #cb-column-buttons {
  flex: 0.3 0.3 0;
}
#cookie-banner #cb-column-text,
#cookie-banner #cb-column-text a,
#cookie-banner #cb-column-buttons button {
  font-size: 14px;
}
#cookie-banner button {
  float: right;
  margin-left: 15px;
}
#footer {
  background: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
}
#footer button {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  float: right;
}

a {
  color: #00aeef;
  font-size: 16px;
}
a:hover {
  color: #008bbf;
}
button:hover:enabled,
label,
input[type="radio"] {
  cursor: pointer;
}
/*.people ul,.process ul,.technology ul */
ul {
  list-style-type: none;
  padding: 0;
}
input[type="radio"]::before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #00aeef;
}
input[type="radio"]:checked::before {
  transform: scale(1);
}
input[type="radio"] {
  height: 24px;
  width: 24px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  border: 1px solid #98aec1;
  border-radius: 50%;
  margin-right: 10px;
  display: grid;
  place-content: center;
}
input[type="radio"]:checked {
  border-color: #00aeef;
}
input[type="radio"]:checked + label {
  font-weight: bold;
}
label {
  margin-right: 40px;
  line-height: 24px;
}
label:last-child {
  margin-right: unset;
}
button {
  font-family: "MuseoSans Regular";
  cursor: pointer;
  border-radius: 20px;
  border: none;
  background: rgb(0, 174, 239);
  color: #fff;
  padding: 10px 30px 10px 30px;
  font-size: 15px;
}
button:hover {
  background-color: #008bbf;
}

.clearfix,
.web-only-clearfix {
  clear: both;
}
.respect-linebreaks {
  white-space: pre-line;
}

.content {
  margin-bottom: 40px;
}
.btn {
  margin-top: 48px;
  margin-bottom: 35px;
  float: right;
}
.btninactive {
  background-color: rgba(243, 248, 250, 1);
  border-color: rgba(243, 248, 250, 1);
  color: rgba(0, 54, 101, 0.5);
}
.btninactive:hover {
  background: rgba(243, 248, 250, 1);
  cursor: not-allowed;
}
.kbc-mini-button {
  border-radius: 20px;
  border: 1px solid;
  background-color: unset;
}
.kbc-grand-bold {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}
.kbc-extra-large {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}
.kbc-medium-bold {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}
.kbc-normal-bold {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.kbc-normal-light {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}
.kbc-normal {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}
.kbc-small {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.kbc-color-primary-light {
  color: #00aeef;
}
.kbc-href {
  color: #00aeef;
  font-size: 12px;
  line-height: 16px;
  text-decoration: underline;
}
.kbc-href:hover {
  color: #008bbf;
}
.kbc-href:focus {
  border: 1px dashed #00aeef;
  border-radius: 4px;
}
.fake-href-btn {
  background-color: unset;
  padding: unset;
  border-radius: unset;
}
.fake-href-btn:hover {
  background-color: unset;
}
.small-title {
  font-weight: bold;
  opacity: 0.5;
}
.font-size-title-small {
  font-size: 14px;
}
.font-size-title {
  font-size: 16px;
}
.font-size-title-middle {
  font-size: 18px;
}
.font-size-title-big {
  font-size: 28px;
  line-height: 36px;
}
.font-bold {
  font-weight: bold;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-25 {
  margin-top: 25px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-top-100 {
  margin-top: 100px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.line-height-20 {
  line-height: 20px;
}

@media only screen and (max-width: 768px) {
  #cookie-banner .cb-content {
    flex-direction: column;
  }
  #cookie-banner #cb-column-text {
    margin-bottom: 15px;
  }
  #cookie-banner #cb-column-text,
  #cookie-banner #cb-column-text a,
  #cookie-banner #cb-column-buttons button {
    font-size: 12px;
  }
  .Cookie > * {
    margin: unset;
  }
  .content {
    width: 90%;
  }
  .font-size-title-big {
    font-size: 20px;
  }
}

@media (max-width: 320px) {
  #logoHeaderLogo #sam-logo {
    width: 34px;
    height: 34px;
  }
  #logoHeaderName {
    font-size: 16px;
  }
}
</style>
