import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("../views/Index.vue"),
  },
  {
    path: "/people",
    name: "People",
    component: () => import("../views/People.vue"),
  },
  {
    path: "/process",
    name: "Process",
    component: () => import("../views/Process.vue"),
  },
  {
    path: "/technology",
    name: "Technology",
    component: () => import("../views/Technology.vue"),
  },
  {
    path: "/report",
    name: "Report",
    component: () => import("../views/Report.vue"),
  },
  /*
  {
    path: "/policy",
    name: "Policy",
    component: () => import("../views/Policy.vue"),
  },
  */
  {
    path: "*",
    component: () => import("../views/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
