import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import store from "./store";
//import VueAnalytics from "vue-analytics";

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");

/*
Vue.use(VueAnalytics, {
  id: "G-P6M0TG276X",
  disabled: true,
});
*/
